import { AssetDto } from '@/shared/lib/sj-orm/models/asset.dto';
import { AssistantMnemonicDto } from '@/shared/lib/sj-orm/models/assistant-mnemonic.dto';
import { BeneficiaryDto } from '@/shared/lib/sj-orm/models/beneficiary.dto';
import { ContactDto } from '@/shared/lib/sj-orm/models/contact.dto';
import { DelegatingRequestDto } from '@/shared/lib/sj-orm/models/delegating-request.dto';
import { AssetInformationDocumentDto } from '@/shared/lib/sj-orm/models/document/asset-information.document.dto';
import { BeneficiaryPersonalDataDocumentDto } from '@/shared/lib/sj-orm/models/document/beneficiary-personal-data.document.dto';
import { PassportDocumentDto } from '@/shared/lib/sj-orm/models/document/passport.document.dto';
import { PersonalIdentifierDocumentDto } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { PrivateDocumentDto } from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { SoweAttachedPersonalIdentifierDto } from '@/shared/lib/sj-orm/models/document/sowe-attached-personal-identifier.document.dto';
import { UnknownDocumentDto } from '@/shared/lib/sj-orm/models/document/unknown.document.dto';
import { EncryptedFileKeyDto } from '@/shared/lib/sj-orm/models/encrypted-file-key.dto';
import { MigrationApplyResultDto } from '@/shared/lib/sj-orm/models/migration-apply-result.dto';
import { MyHubDto } from '@/shared/lib/sj-orm/models/my-hub.dto';
import { NotificationDto } from '@/shared/lib/sj-orm/models/notification.dto';
import { SoweDto } from '@/shared/lib/sj-orm/models/sowe/sowe.dto';
import { SoweAttachmentDto } from '@/shared/lib/sj-orm/models/sowe/sowe-attachment.dto';
import { SJCollection } from '@/shared/lib/sj-orm/sj-collection';

import { FileMetaInfoDto } from './models/file-meta-info.dto';
import { ScenarioMetaInfoDto } from './models/scenario-meta-info.dto';

export enum CollectionName {
  ASSETS = 'assets',
  SOWE = 'sowe',
  SOWE_ATTACHMENTS = 'soweAttachments',
  ENCRYPTED_FILE_KEY = 'encryptedFileKey',
  BENEFICIARIES = 'beneficiaries',
  DELEGATING_REQUESTS = 'delegatingRequests',
  CONTACTS = 'contacts',
  FILE_META_INFO = 'fileMetaInfos',
  SCENARIO_META_INFO = 'scenarioMetaInfos',

  // Documents
  PASSPORT_DOCUMENT = 'passportDocuments',
  UNKNOWN_DOCUMENT = 'unknownDocuments',
  BENEFICIARY_PERSONAL_DATA_DOCUMENT = 'beneficiaryPersonalDataDocuments',
  ASSET_INFORMATION_DOCUMENT = 'assetInformationDocument',
  SOWE_ATTACHED_PERSONAL_IDENTIFIER = 'soweAttachedPersonalIdentifier',
  PRIVATE_DOCUMENTS = 'privateDocuments',
  PERSONAL_IDENTIFIERS_DOCUMENTS = 'personalIdentifiersDocuments',
  ASSISTANT_MNEMONIC = 'assistantMnemonic',
  NOTIFICATION = 'notification',

  MY_HUB = 'myHub',

  MIGRATIONS = 'migrations',
}

export type CollectionsTypeValueMap = {
  [CollectionName.ASSETS]: AssetDto;
  [CollectionName.SOWE]: SoweDto;
  [CollectionName.SOWE_ATTACHMENTS]: SoweAttachmentDto;
  [CollectionName.ENCRYPTED_FILE_KEY]: EncryptedFileKeyDto;
  [CollectionName.BENEFICIARIES]: BeneficiaryDto;
  [CollectionName.DELEGATING_REQUESTS]: DelegatingRequestDto;
  [CollectionName.CONTACTS]: ContactDto;
  [CollectionName.FILE_META_INFO]: FileMetaInfoDto;
  [CollectionName.SCENARIO_META_INFO]: ScenarioMetaInfoDto;
  [CollectionName.PASSPORT_DOCUMENT]: PassportDocumentDto;
  [CollectionName.UNKNOWN_DOCUMENT]: UnknownDocumentDto;
  [CollectionName.BENEFICIARY_PERSONAL_DATA_DOCUMENT]: BeneficiaryPersonalDataDocumentDto;
  [CollectionName.ASSET_INFORMATION_DOCUMENT]: AssetInformationDocumentDto;
  [CollectionName.SOWE_ATTACHED_PERSONAL_IDENTIFIER]: SoweAttachedPersonalIdentifierDto;
  [CollectionName.PRIVATE_DOCUMENTS]: PrivateDocumentDto;
  [CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS]: PersonalIdentifierDocumentDto;
  [CollectionName.ASSISTANT_MNEMONIC]: AssistantMnemonicDto;
  [CollectionName.NOTIFICATION]: NotificationDto;
  [CollectionName.MY_HUB]: MyHubDto;
  [CollectionName.MIGRATIONS]: MigrationApplyResultDto;
};

export interface ICollections {
  assets: SJCollection<AssetDto> | undefined;
  sowe: SJCollection<SoweDto> | undefined;
  soweAttachments: SJCollection<SoweAttachmentDto> | undefined;
  encryptedFileKey: SJCollection<EncryptedFileKeyDto> | undefined;
  beneficiaries: SJCollection<BeneficiaryDto> | undefined;
  delegatingRequests: SJCollection<DelegatingRequestDto> | undefined;
  contacts: SJCollection<ContactDto> | undefined;
  passportDocuments: SJCollection<PassportDocumentDto> | undefined;
  unknownDocuments: SJCollection<UnknownDocumentDto> | undefined;
  beneficiaryPersonalDataDocuments:
    | SJCollection<BeneficiaryPersonalDataDocumentDto>
    | undefined;
  assetInformationDocument:
    | SJCollection<AssetInformationDocumentDto>
    | undefined;
  soweAttachedPersonalIdentifier:
    | SJCollection<SoweAttachedPersonalIdentifierDto>
    | undefined;
  assistantMnemonic: SJCollection<AssistantMnemonicDto> | undefined;
  notification: SJCollection<NotificationDto> | undefined;
  privateDocuments: SJCollection<PrivateDocumentDto> | undefined;
  personalIdentifiersDocuments:
    | SJCollection<PersonalIdentifierDocumentDto>
    | undefined;
  fileMetaInfos: SJCollection<FileMetaInfoDto> | undefined;
  scenarioMetaInfos: SJCollection<ScenarioMetaInfoDto> | undefined;
  myHub: SJCollection<MyHubDto> | undefined;
  migrations: SJCollection<MigrationApplyResultDto> | undefined;
}
