import { FC, memo, PropsWithChildren, useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { useRouter } from 'next/router';
import { create } from 'zustand';

import { useDashboardStore } from '@/features/dashboard/hooks/use-dashboard';
import { KeyPair } from '@/shared/lib/secure-json/core/crypto-core/types';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSecureJsonCollectionsStore } from '@/shared/lib/stores/secure-json-collections.store';
import { useFileLoader } from '@/shared/lib/synchronizer/hooks/use-file-loader';
import { useGqlLoader } from '@/shared/lib/synchronizer/hooks/use-gql-loader';
import { useSynchronizer } from '@/shared/lib/synchronizer/hooks/use-synchronizer';
import {
  SyncQueueJobMethod,
  useSyncQueueStore,
} from '@/shared/lib/synchronizer/sync-queue.store';
import { useAuthStore } from '@/shared/store/auth.store';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { log } from '@/shared/utils/log';

const StoresInitializationProvider: FC<PropsWithChildren> = ({
  children,
}): JSX.Element => {
  const {
    setIsLoading: storeSetIsLoading,
    setIsInitialized: storeSetIsInitialized,
  } = useSynchronizerStoreStore();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const store = useDashboardStore();
  const graphQLLoader = useGqlLoader();
  const fileLoader = useFileLoader();
  const isModeExported = process.env.NEXT_PUBLIC_APP_MODE === 'EXPORTED';
  log.debug('StoresInitializationProvider', { isModeExported });
  const { keyPair } = useKeyPairStore();
  const { token } = useAuthStore();
  const router = useRouter();
  const sjCollectionStore = useSecureJsonCollectionsStore();

  const syncQueueStore = useSyncQueueStore();

  const synchronizer = useSynchronizer(
    keyPair ?? ({} as KeyPair),
    isModeExported ? fileLoader : graphQLLoader,
    // 'Store init',
  );

  const SYNC_INTERVAL_MS = 5000;
  useInterval(() => {
    log.trace(
      'Sync interval. Syncing:',
      synchronizer?.isSyncing,
      'Jobs:',
      syncQueueStore.jobs,
    );
    if (syncQueueStore.jobs.length && !synchronizer?.isSyncing) {
      log.trace('Sync interval: start sync.');
      synchronizer?.sync();
    }
  }, SYNC_INTERVAL_MS);

  const ADD_JOB_INTERVAL_MS = 1000;
  useInterval(() => {
    const notSyncedCollectionNames = Array.from(
      sjCollectionStore.syncStatus.entries(),
    )
      .filter(([, status]) => !status)
      .map(([name]) => name);

    for (const notSyncedCollectionName of notSyncedCollectionNames) {
      if (!sjCollectionStore.collections.get(notSyncedCollectionName)) {
        continue;
      }
      log.trace('Add job for not synced collection:', notSyncedCollectionName);
      syncQueueStore.addJob({
        method: SyncQueueJobMethod.PUSH,
        collections: [
          {
            collectionName: notSyncedCollectionName,
            collection: sjCollectionStore.collections
              .get(notSyncedCollectionName)!
              .toString(),
          },
        ],
      });
      sjCollectionStore.setSyncStatus(notSyncedCollectionName, true);
    }
  }, ADD_JOB_INTERVAL_MS);

  useEffect(() => {
    if (!token && !isModeExported && isInitialized) {
      setIsInitialized(false);
      storeSetIsInitialized(false);
    }
    console.log(router.pathname);
    if (!isInitialized && keyPair?.privateKey && (token || isModeExported)) {
      storeSetIsLoading(true);
      log.info('Start stores initialization...');
      setTimeout(
        () => {
          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.ASSETS,
          });
          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.BENEFICIARIES,
          });
          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.SCENARIO_META_INFO,
          });
          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.MIGRATIONS,
          });

          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.SOWE,
          });

          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.SOWE_ATTACHMENTS,
          });

          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.ENCRYPTED_FILE_KEY,
          });

          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.DELEGATING_REQUESTS,
          });

          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.CONTACTS,
          });

          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.FILE_META_INFO,
          });

          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.UNKNOWN_DOCUMENT,
          });

          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.BENEFICIARY_PERSONAL_DATA_DOCUMENT,
          });

          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.ASSET_INFORMATION_DOCUMENT,
          });

          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.SOWE_ATTACHED_PERSONAL_IDENTIFIER,
          });

          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.PRIVATE_DOCUMENTS,
          });

          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
          });

          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.ASSISTANT_MNEMONIC,
          });

          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.NOTIFICATION,
          });

          syncQueueStore.addJob({
            method: SyncQueueJobMethod.SINGLE_PULL,
            collectionName: CollectionName.MY_HUB,
          });
        },
        //workaround to trigger pull only after dashboard is initialized
        store.assets?.length ||
          store.beneficiaries?.length ||
          store.beneficiariesValues?.length
          ? 4_000
          : 0,
      );
      setIsInitialized(true);
      storeSetIsInitialized(true);
    } else {
      storeSetIsLoading(false);
      log.info('Stores already initialized', {
        isInitialized,
        keyPair,
        token,
      });
    }
  }, [
    isInitialized,
    keyPair,
    token,
    store,
    storeSetIsLoading,
    storeSetIsInitialized,
  ]);

  return <>{children}</>;
};

type TSynchronizerStore = {
  isInitialized: boolean;
  isLoading: boolean;
  setIsInitialized: (is: boolean) => void;
  setIsLoading: (is: boolean) => void;
  clear: () => void;
};

export const useSynchronizerStoreStore = create<TSynchronizerStore>((set) => ({
  isLoading: true,
  isInitialized: false,
  setIsLoading: (is: boolean): void => set({ isLoading: is }),
  setIsInitialized: (is: boolean): void => set({ isInitialized: is }),
  clear: (): void => set({ isLoading: false, isInitialized: false }),
}));

export default memo(StoresInitializationProvider);
