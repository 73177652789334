import { useCallback, useEffect, useState } from 'react';

import {
  useWebPushSubscribeMutation,
  useWebPushUnsubscribeMutation,
  WebPushSubscriptionInput,
} from '@/shared/generated/graphql';
import { useAuthStore } from '@/shared/store/auth.store';
import { log } from '@/shared/utils/log';

// const WEB_PUSH_PRIVATE_KEY = 'iW5QucQsAJIvMT2ARjbngtH-tYpevBD6x-xFuldkM34';
const WEB_PUSH_PUBLIC_KEY =
  'BBjAPt8UUiHtQ0Gq_QOGIFenpP430cOI2IMcwT0DNTlOO9MLzYgnB6xwmSqqF3LacYthwX-8lOPPY9sBHfhOSBg';

const NotificationsComponent = () => {
  const [grantedPermission, setGrantedPermission] = useState('default');

  const [subscription, setSubscription] = useState<
    WebPushSubscriptionInput | undefined
  >(undefined);

  const { token } = useAuthStore();

  const [subscribe] = useWebPushSubscribeMutation();
  const [unsubscribe] = useWebPushUnsubscribeMutation();

  useEffect(() => {
    if ('Notification' in window) {
      setGrantedPermission(Notification.permission);
    }
  }, []);

  useEffect(() => {
    if (grantedPermission && 'serviceWorker' in navigator && token) {
      try {
        navigator.serviceWorker.register('/serviceworker.js', { scope: '/' });
        navigator.serviceWorker.ready
          .then(registerServiceWorker)
          .catch((error) => console.error(error));
      } catch (e) {
        log.error((e as Error).message, e);
      }
    } else {
      requestPermission();
    }
  }, [grantedPermission, token]);

  const logoutHandler = async (data: unknown) => {
    const _data = data as CustomEvent;
    if (_data?.detail?.isLogout && subscription) {
      await unsubscribe({
        variables: { subscription },
      });
    }
  };

  useEffect(() => {
    window.addEventListener('logout.owner.one', logoutHandler);

    return () => window.removeEventListener('logout.owner.one', logoutHandler);
  }, []);

  const registerServiceWorker = useCallback(
    (registration: ServiceWorkerRegistration) => {
      // Use the PushManager to get the user's subscription to the push service.
      registration.pushManager
        .getSubscription()
        .then(async (subs) => {
          // If a subscription was found, return it.
          if (subs) {
            return subs;
          }

          // Otherwise, subscribe the user (userVisibleOnly allows to specify that we don't plan to
          // send notifications that don't have a visible effect for the user).
          return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: WEB_PUSH_PUBLIC_KEY,
          });
        })
        .then(async (subs: PushSubscription) => {
          const subCopy = JSON.parse(JSON.stringify(subs));
          setSubscription({
            endpoint: subCopy.endpoint,
            keys: subCopy.keys,
          });
          await subscribe({
            variables: {
              subscription: {
                endpoint: subCopy.endpoint,
                keys: subCopy.keys,
              },
            },
          });
          return subs;
        })
        .catch(() => {
          // console.error(`REGISTRATION SW ERROR:`, error);
        });
    },
    [],
  );

  const requestPermission = useCallback(() => {
    if ('Notification' in window) {
      Notification.requestPermission()
        .then((permission) => {
          setGrantedPermission(permission);
          return permission;
        })
        .catch((error) => {
          console.error('request notification permission failed', error);
        });
    }
  }, []);

  return null;
};

export default NotificationsComponent;
