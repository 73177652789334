import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  ContactDto,
  ContactType,
} from '@/shared/lib/sj-orm/models/contact.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum FuturesType {
  deliveryBased = 'deliveryBased',
  cashSettled = 'cashSettled',
}

const FuturesList = [
  {
    label: 'forms_assets:futures.enums.type.deliveryBased',
    key: FuturesType.deliveryBased,
  },
  {
    label: 'forms_assets:futures.enums.type.cashSettled',
    key: FuturesType.cashSettled,
  },
];

export enum BasicAssetType {
  commodity = 'commodity ',
  equity = 'equity',
  currency = 'currency',
  index = 'index',
  interestRate = 'interestRate',
  futuresContract = 'futuresContract',
  other = 'other',
}

const BasicAssetList = [
  {
    label: 'forms_assets:futures.enums.basicAssetType.commodity',
    key: BasicAssetType.commodity,
  },
  {
    label: 'forms_assets:futures.enums.basicAssetType.equity',
    key: BasicAssetType.equity,
  },
  {
    label: 'forms_assets:futures.enums.basicAssetType.currency',
    key: BasicAssetType.currency,
  },
  {
    label: 'forms_assets:futures.enums.basicAssetType.index',
    key: BasicAssetType.index,
  },
  {
    label: 'forms_assets:futures.enums.basicAssetType.interestRate',
    key: BasicAssetType.interestRate,
  },
  {
    label: 'forms_assets:futures.enums.basicAssetType.futuresContract',
    key: BasicAssetType.futuresContract,
  },
  {
    label: 'forms_assets:futures.enums.basicAssetType.other',
    key: BasicAssetType.other,
  },
];

export enum TradeDirectionType {
  long = 'long',
  short = 'short',
}

const TradeDirectionList = [
  {
    label: 'forms_assets:futures.enums.tradeDirection.long',
    key: TradeDirectionType.long,
  },
  {
    label: 'forms_assets:futures.enums.tradeDirection.short',
    key: TradeDirectionType.short,
  },
];

export enum SellerType {
  legalEntity = 'legalEntity',
  person = 'person',
}

const SellerList = [
  {
    label: 'forms_assets:futures.enums.sellerType.legalEntity',
    key: SellerType.legalEntity,
  },
  {
    label: 'forms_assets:futures.enums.sellerType.person',
    key: SellerType.person,
  },
];

type FuturesPart1 = Omit<BaseDto, 'id'> & {
  nickName: string;
  country: string;
  type: FuturesType;
  purchaseValue: number;
  basicAssetName: string;
  basicAssetType: BasicAssetType;
  otherBasicAssetType: string;
  basicAssetAmount: number;
  broker: DtoId[];
};

type FuturesPart2 = Omit<BaseDto, 'id'> & {
  basicPrice?: number;
  brokerAccountNumber?: string;
  tradeDirection?: TradeDirectionType;
  expirationDate?: number;
  marginCollateral?: string;
  exchangeName: string;
  seller?: SellerType;
  legalEntityName?: string;
  personFullName?: string;
  insurance?: boolean;
  dealReserves?: boolean;
  dealReservesData?: string;
  depositoryStatements?: DtoId[];
  strategy?: string;
  powersOfAttorney?: DtoId[];
  otherDocuments?: DtoId[];
  comments?: string;
};

type FuturesHelpPart1 = {
  nickName: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  type: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  basicAssetName: QuestionType.TEXT;
  basicAssetType: QuestionType.SELECT_FROM_LIST;
  otherBasicAssetType: QuestionType.TEXT;
  basicAssetAmount: QuestionType.NUMBER;
  broker: QuestionType.PICK_ARRAY_OF_DTO;
};

type FuturesHelpPart2 = {
  basicPrice?: QuestionType.CURRENCY_CALCULATOR;
  tradeDirection?: QuestionType.SELECT_FROM_LIST;
  expirationDate?: QuestionType.DATE_TIME;
  marginCollateral?: QuestionType.TEXT;
  exchangeName: QuestionType.TEXT;
  seller?: QuestionType.SELECT_FROM_LIST;
  legalEntityName?: QuestionType.TEXT;
  personFullName?: QuestionType.TEXT;
  brokerAccountNumber?: QuestionType.TEXT;
  insurance?: QuestionType.CHECKBOX;
  dealReserves?: QuestionType.CHECKBOX;
  dealReservesData?: QuestionType.TEXT;
  strategy?: QuestionType.TEXT;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
};

type FuturesFormType = [
  FuturesFormPart1Type,
  FuturesFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

type FuturesFormPart1Type = TDtoWrapper<FuturesPart1, FuturesHelpPart1>;
type FuturesFormPart2Type = TDtoWrapper<FuturesPart2, FuturesHelpPart2>;

const FuturesFormPart1: FuturesFormPart1Type = {
  nickName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:futures.inputs.type.label',
    tooltip: 'forms_assets:futures.inputs.type.tooltip',
    required: true,
    value: FuturesType.cashSettled,
    items: FuturesList,
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:futures.inputs.purchaseValue.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  basicAssetType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:futures.inputs.basicAssetType.label',
    tooltip: 'forms_assets:futures.inputs.basicAssetType.tooltip',
    items: BasicAssetList,
    required: true,
    value: BasicAssetType.commodity,
  },
  otherBasicAssetType: {
    type: QuestionType.TEXT,
    label: 'forms_assets:futures.inputs.otherBasicAssetType.label',
    required: true,
    value: '',
    showIf: {
      basicAssetType: {
        value: BasicAssetType.other,
      },
    },
  },
  basicAssetName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:futures.inputs.basicAssetName.label',
    required: true,
    value: '',
  },
  basicAssetAmount: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:futures.inputs.basicAssetAmount.label',
    required: true,
    value: 0,
  },
  broker: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:futures.inputs.broker.label',
    tooltip: 'forms_assets:futures.inputs.broker.tooltip',
    items: 'contacts',
    required: true,
    value: [],
    filter: (contact) =>
      (contact as unknown as ContactDto).type === ContactType.BROKER,
    dtoType: ContactType.BROKER,
  },
};

const FuturesFormPart2: FuturesFormPart2Type = {
  basicPrice: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:futures.inputs.basicPrice.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 1,
  },
  tradeDirection: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:futures.inputs.tradeDirection.label',
    tooltip: 'forms_assets:futures.inputs.tradeDirection.tooltip',
    order: 2,
    items: TradeDirectionList,
  },
  expirationDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:futures.inputs.expirationDate.label',
    tooltip: 'forms_assets:futures.inputs.expirationDate.tooltip',
    order: 3,
  },
  marginCollateral: {
    type: QuestionType.TEXT,
    label: 'forms_assets:futures.inputs.marginCollateral.label',
    tooltip: 'forms_assets:futures.inputs.marginCollateral.tooltip',
    order: 4,
  },
  exchangeName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:futures.inputs.exchangeName.label',
    order: 5,
  },
  seller: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:futures.inputs.seller.label',
    order: 6,
    items: SellerList,
  },
  legalEntityName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:ovcOptions.inputs.legalEntityName.label',
    order: 7,
    showIf: {
      seller: {
        value: SellerType.legalEntity,
      },
    },
  },
  personFullName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:ovcOptions.inputs.personFullName.label',
    order: 8,
    showIf: {
      seller: {
        value: SellerType.person,
      },
    },
  },
  brokerAccountNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:futures.inputs.brokerAccountNumber.label',
    order: 9,
  },
  insurance: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:futures.inputs.insurance.label',
    order: 10,
  },
  dealReserves: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:futures.inputs.dealReserves.label',
    tooltip: 'forms_assets:futures.inputs.dealReserves.tooltip',
    order: 11,
  },
  dealReservesData: {
    type: QuestionType.TEXT,
    label: 'forms_assets:futures.inputs.dealReservesData.label',
    order: 12,
    showIf: {
      dealReserves: {
        value: true,
      },
    },
  },
  strategy: {
    type: QuestionType.TEXT,
    label: 'forms_assets:futures.inputs.strategy.label',
    tooltip: 'forms_assets:futures.inputs.strategy.tooltip',
    order: 13,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory ===
      PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  otherDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherDocuments.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

const FuturesForm: FuturesFormType = [
  FuturesFormPart1,
  FuturesFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgent',
    'taxAgentOther',
    'publicFundTaxAgent',
    'publicTaxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];
export { FuturesForm };
