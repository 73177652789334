import { useMemo } from 'react';

import {
  ISecureJsonCollectionsStore,
  useSecureJsonCollectionsStore,
} from '@/shared/lib/stores/secure-json-collections.store';

import { SJDatabase } from '../core';

export const getSJDatabase = (
  store: ISecureJsonCollectionsStore,
): SJDatabase => {
  return new SJDatabase(store);
};

export const useSJDatabase = (): SJDatabase => {
  const store = useSecureJsonCollectionsStore();
  return useMemo(() => getSJDatabase(store), [store]);
};
