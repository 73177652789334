import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';

export interface BeneficiaryDto extends BaseDto {
  centralDbProfileId: number;

  nickName: string;
  firstName: string;
  lastName: string;
  group: BeneficiaryGroup;
  email: string;
  linkedCountryCode: string; //todo remove after creating new filter bu country

  personalDataDtoId?: DtoId;

  invitationCode?: string;

  assetId?: DtoId;
  formId?: DtoId;
}

export enum BeneficiaryGroup {
  FAMILY = 'FAMILY',
  CLAN = 'CLAN',
  CIRCLE = 'CIRCLE',
}
