import {
  FormItemLabelType,
  FormLabelItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import {
  ActivityList,
  AssetsStatisticsPart,
  HighMediumLowList,
  InformationDisclosureList,
  InvestmentsGoalsList,
  PerspectivesList,
  PublicFundTaxAgentList,
  PublicFundTaxAgentType,
  TaxAgentList,
  TaxAgentType,
  TaxAgentWithBrokerList,
} from '@/shared/lib/sj-orm/models/asset.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

type AssetsStatisticsHelpPart = {
  taxBurden?: QuestionType.SELECT_FROM_LIST;
  taxAgent?: QuestionType.SELECT_FROM_LIST;
  publicFundTaxAgent?: QuestionType.SELECT_FROM_LIST;
  taxAgentWithBroker?: QuestionType.SELECT_FROM_LIST;
  taxAgentOther?: QuestionType.TEXT;
  publicTaxAgentOther?: QuestionType.TEXT;
  taxAgentWithBrokerOther?: QuestionType.TEXT;
  limitations?: QuestionType.CHECKBOX;
  limitationsComments?: QuestionType.TEXT_MULTILINE;
  myTodo?: QuestionType.CHECKBOX;
  myTodoComments?: QuestionType.TEXT_MULTILINE;
  liquidity?: QuestionType.SELECT_FROM_LIST;
  activity?: QuestionType.SELECT_FROM_LIST;
  investmentsGoals?: QuestionType.SELECT_FROM_LIST;
  outlook?: QuestionType.SELECT_FROM_LIST;
  riskRate?: QuestionType.SELECT_FROM_LIST;
  informationDisclosure?: QuestionType.SELECT_FROM_LIST;
  informationDisclosureDetails?: QuestionType.TEXT_MULTILINE;
  specificsOfTaxation?: QuestionType.TEXT_MULTILINE;
  profitability?: QuestionType.CURRENCY_CALCULATOR;
  profitabilityFact?: QuestionType.CURRENCY_CALCULATOR;
  profitabilityPlan?: QuestionType.CURRENCY_CALCULATOR;
  commentsOnTheAsset?: QuestionType.TEXT_MULTILINE;
};

export type AssetsStatisticsPartType = TDtoWrapper<
  AssetsStatisticsPart,
  AssetsStatisticsHelpPart
>;

export const AssetsStatisticsFormPartFull: AssetsStatisticsPartType = {
  specificsOfTaxation: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.specificsOfTaxation.label',
    order: 1,
  },
  taxBurden: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.taxBurden.label',
    items: HighMediumLowList,
    order: 2,
    tooltip: 'forms_assets:common.inputs.taxBurden.tooltip',
  },
  taxAgent: {
    type: QuestionType.SELECT_FROM_LIST,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    label: 'forms_assets:common.inputs.taxAgent.label',
    items: TaxAgentList,
    order: 3,
  },
  taxAgentWithBroker: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.taxAgent.label',
    items: TaxAgentWithBrokerList,
    order: 3,
  },
  publicFundTaxAgent: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.taxAgent.label',
    items: PublicFundTaxAgentList,
    order: 3,
  },
  taxAgentOther: {
    type: QuestionType.TEXT,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    label: 'forms_assets:common.inputs.otherTaxAgent.label',
    showIf: {
      taxAgent: {
        value: TaxAgentType.other,
      },
    },
    order: 4,
  },
  publicTaxAgentOther: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.otherTaxAgent.label',
    showIf: {
      publicFundTaxAgent: {
        value: PublicFundTaxAgentType.other,
      },
    },
    order: 4,
  },
  taxAgentWithBrokerOther: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.otherTaxAgent.label',
    showIf: {
      taxAgentWithBroker: {
        value: PublicFundTaxAgentType.other,
      },
    },
    order: 4,
  },
  limitations: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:common.inputs.limitations.label',
    order: 5,
    tooltip: 'forms_assets:common.inputs.limitations.tooltip',
  },
  limitationsComments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.limitationsComments.label',
    showIf: {
      limitations: {
        value: true,
      },
    },
    order: 6,
  },
  myTodo: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:common.inputs.myTodo.label',
    order: 7,
    tooltip: 'forms_assets:common.inputs.myTodo.tooltip',
  },
  myTodoComments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.myTodoComments.label',
    showIf: {
      myTodo: {
        value: true,
      },
    },
    order: 8,
  },
  liquidity: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.liquidity.label',
    items: HighMediumLowList,
    order: 9,
    tooltip: 'forms_assets:common.inputs.liquidity.tooltip',
  },
  activity: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.activity.label',
    tooltip: 'forms_assets:common.inputs.activity.tooltip',
    items: ActivityList,
    order: 10,
  },
  investmentsGoals: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.goal.label',
    tooltip: 'forms_assets:common.inputs.goal.tooltip',
    items: InvestmentsGoalsList,
    order: 11,
  },
  outlook: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.outlook.label',
    tooltip: 'forms_assets:common.inputs.outlook.tooltip',
    items: PerspectivesList,
    order: 12,
  },
  riskRate: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.riskRate.label',
    items: HighMediumLowList,
    order: 13,
    tooltip: 'forms_assets:common.inputs.riskRate.tooltip',
  },
  informationDisclosure: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.informationDisclosure.label',
    tooltip: 'forms_assets:common.inputs.informationDisclosure.tooltip',
    items: InformationDisclosureList,
    order: 14,
  },
  informationDisclosureDetails: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.informationDisclosureDetails.label',
    order: 15,
  },
  profitability: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:common.inputs.profitability.label',
    tooltip: 'forms_assets:common.inputs.profitability.tooltip',
    order: 16,
  },
  profitabilityPlan: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:common.inputs.profitabilityPlan.label',
    order: 17,
  },
  profitabilityFact: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:common.inputs.profitabilityFact.label',
    order: 18,
  },
  commentsOnTheAsset: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.commentsOnTheAsset.label',
    order: 19,
  },
};

export const AssetsStatisticsFormTextsPartFull: {
  [key: string]: FormItemLabelType;
} = {
  profitabilityText: {
    type: FormLabelItem.text,
    label: {
      prefix: 'forms_assets:brokerageAccount.inputs.investedAssets.label',
      fieldKey: 'purchaseValue',
      postfix: '',
    },
    order: 15,
  },
};

export const assetsStatisticsBuilder = (
  excludeItems?: Array<keyof AssetsStatisticsPart>,
) => {
  if (!Array.isArray(excludeItems) || !excludeItems.length) {
    return {
      form: AssetsStatisticsFormPartFull,
      texts: AssetsStatisticsFormTextsPartFull,
    };
  }

  const copy = Object.assign({}, AssetsStatisticsFormPartFull);

  let cursor = 0;
  const length = excludeItems.length;

  while (cursor < length) {
    delete copy[excludeItems[cursor]];
    cursor++;
  }

  return {
    form: copy,
    texts: AssetsStatisticsFormTextsPartFull,
  };
};
