import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { PersonalIdentifierDocumentDto } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum BloodType {
  A_PLUS = 'A+',
  A_MINUS = 'A-',
  B_PLUS = 'B+',
  B_MINUS = 'B-',
  O_PLUS = 'O+',
  O_MINUS = 'O-',
  AB_PLUS = 'AB+',
  AB_MINUS = 'AB-',
}

const BloodTypeList = [
  {
    label: BloodType.A_PLUS,
    key: BloodType.A_PLUS,
  },
  {
    label: BloodType.A_MINUS,
    key: BloodType.A_MINUS,
  },
  {
    label: BloodType.B_PLUS,
    key: BloodType.B_PLUS,
  },
  {
    label: BloodType.B_MINUS,
    key: BloodType.B_MINUS,
  },
  {
    label: BloodType.O_PLUS,
    key: BloodType.O_PLUS,
  },
  {
    label: BloodType.O_MINUS,
    key: BloodType.O_MINUS,
  },
  {
    label: BloodType.AB_PLUS,
    key: BloodType.AB_PLUS,
  },
  {
    label: BloodType.AB_MINUS,
    key: BloodType.AB_MINUS,
  },
];

export enum GenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

const GenderList = [
  {
    label: 'Male',
    key: GenderType.MALE,
  },
  {
    label: 'Female',
    key: GenderType.FEMALE,
  },
  {
    label: 'Other',
    key: GenderType.OTHER,
  },
];

export type UserDetailsDtoType = Omit<
  PersonalIdentifierDocumentDto,
  'id' | 'personalIdentifierType' | 'type'
> & {
  shortname?: string;
  secondName: string;
  firstName: string;
  middleName?: string;
  fullName?: string;
  gender?: GenderType;
  bloodType?: BloodType;
  cityOfBirth?: string;
  countryOfBirth?: string;
  birthDate?: number;
  scan?: string;
  comments?: string;
};

export type UserDetailsDtoHelpType = {
  shortname?: QuestionType.TEXT;
  secondName: QuestionType.TEXT;
  firstName: QuestionType.TEXT;
  middleName?: QuestionType.TEXT;
  fullName?: QuestionType.TEXT;
  gender?: QuestionType.SELECT_FROM_LIST;
  bloodType?: QuestionType.SELECT_FROM_LIST;
  cityOfBirth?: QuestionType.TEXT;
  countryOfBirth?: QuestionType.SELECT_FROM_LIST;
  birthDate?: QuestionType.DATE_TIME;
  scan?: QuestionType.FILE_UPLOAD;
  comments?: QuestionType.TEXT_MULTILINE;
};

type UserDetailsFormType = TDtoWrapper<
  UserDetailsDtoType,
  UserDetailsDtoHelpType
>;

export const UserDetailsForm: UserDetailsFormType = {
  shortname: {
    type: QuestionType.TEXT,
    label: '*Short name',
    required: true,
    value: '',
  },
  secondName: {
    type: QuestionType.TEXT,
    label: '*Second name (In latin)',
    required: true,
    value: '',
  },
  firstName: {
    type: QuestionType.TEXT,
    label: '*First name (In latin)',
    required: true,
    value: '',
  },
  middleName: {
    type: QuestionType.TEXT,
    label: 'Middle name (In latin)',
  },
  fullName: {
    type: QuestionType.TEXT,
    label: 'Full name in native language',
  },
  gender: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Gender',
    items: GenderList,
  },
  bloodType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Blood type',
    items: BloodTypeList,
  },
  cityOfBirth: {
    type: QuestionType.TEXT,
    label: 'City of birth',
  },
  countryOfBirth: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Country of birth',
    items: CountriesList,
    withSearch: true,
  },
  birthDate: {
    type: QuestionType.DATE_TIME,
    label: 'Birth date',
  },
  scan: {
    type: QuestionType.FILE_UPLOAD,
    label: 'Scan / Photo',
    tooltip: 'Take or upload a photo/scan of your document',
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'Comments',
  },
};
