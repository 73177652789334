import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { PersonalIdentifierDocumentDto } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export type MilitaryIDDtoType = Omit<
  PersonalIdentifierDocumentDto,
  'id' | 'personalIdentifierType' | 'type'
> & {
  name: string;
  names: string;
  surnames: string;
  country: string;
  number?: string;
  dateOfIssue?: number;
  endDate?: number;
  other?: string;
  scan?: string;
  storageLocation?: string;
  comments?: string;
};
export type MilitaryIDDtoHelpType = {
  name: QuestionType.TEXT;
  names: QuestionType.TEXT;
  surnames: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  number?: QuestionType.TEXT;
  dateOfIssue?: QuestionType.DATE_TIME;
  endDate?: QuestionType.DATE_TIME;
  other?: QuestionType.TEXT;
  scan?: QuestionType.FILE_UPLOAD;
  storageLocation?: QuestionType.TEXT;
  comments?: QuestionType.TEXT_MULTILINE;
};

type MilitaryIDFormType = TDtoWrapper<MilitaryIDDtoType, MilitaryIDDtoHelpType>;

export const MilitaryIDForm: MilitaryIDFormType = {
  name: {
    type: QuestionType.TEXT,
    label: '*Document name',
    required: true,
    value: '',
    tooltip: 'Enter a document name for display within the system',
  },
  names: {
    type: QuestionType.TEXT,
    label: '*Name(s)',
    placeholder: 'Fill in as in the document',
    required: true,
    value: '',
  },
  surnames: {
    type: QuestionType.TEXT,
    label: '*Surname(s)',
    placeholder: 'Fill in as in the document',
    required: true,
    value: '',
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: '*Country',
    items: CountriesList,
    withSearch: true,
    required: true,
    value: '',
  },
  number: {
    type: QuestionType.TEXT,
    label: 'Document number',
  },
  dateOfIssue: {
    type: QuestionType.DATE_TIME,
    label: 'Start date',
  },
  endDate: {
    type: QuestionType.DATE_TIME,
    label: 'End date',
  },
  other: {
    type: QuestionType.TEXT,
    label: 'Other data',
  },
  scan: {
    type: QuestionType.FILE_UPLOAD,
    label: 'Document scan',
  },
  storageLocation: {
    type: QuestionType.TEXT,
    label: 'Storage location',
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'Comments',
  },
};
