import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import {
  HighMediumLowList,
  HighMediumLowType,
} from '@/shared/lib/sj-orm/models/asset.dto';
import { DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  ContactDto,
  ContactType,
} from '@/shared/lib/sj-orm/models/contact.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import { PrivateDocumentCategory } from '../../sj-orm/models/document/private.document.dto';
import { SoweType } from '../../sj-orm/models/sowe/sowe.dto';

export enum ValidUntilType {
  UpToThreeMonths = 'UpToThreeMonths',
  UpToSixMonths = 'UpToSixMonths',
  Annual = 'Annual', // 12 months
  SomeYears = 'SomeYears',
}

type BankContactDataPartType = Omit<ContactDto, 'id' | 'type'> & {
  selectedCountry: string;
  bankAddress: string;
  nickname: string;
  //
  bankName?: string;
  bankCity?: string;
  bankReliability?: HighMediumLowType;
  bankConvenience?: HighMediumLowType;
  bankLegalName?: string;
  swiftOrBic?: string;
  bic?: string;
  sepa?: string;
  contactPerson?: DtoId;
  businessCard?: DtoId;
  // documents
  contract?: DtoId;
  paymentDetails?: DtoId;
  comments?: string;
  statement?: DtoId; // private
  recommendations?: DtoId[]; // private
  sowe?: DtoId; // sowe
  bankFilesAndOther?: DtoId[];
};

type BankContactHelpPartType = {
  selectedCountry: QuestionType.SELECT_FROM_LIST;
  bankAddress: QuestionType.TEXT;
  nickname: QuestionType.TEXT;
  //
  bankName?: QuestionType.TEXT;
  bankCity?: QuestionType.TEXT;
  bankReliability?: QuestionType.SELECT_FROM_LIST;
  bankConvenience?: QuestionType.SELECT_FROM_LIST;
  bankLegalName?: QuestionType.TEXT;
  swiftOrBic?: QuestionType.TEXT;
  bic?: QuestionType.TEXT;
  sepa?: QuestionType.TEXT;
  contactPerson?: QuestionType.PICK_ARRAY_OF_DTO;
  businessCard?: QuestionType.PICK_ARRAY_OF_DTO;
  // documents
  contract?: QuestionType.PICK_ARRAY_OF_DTO;
  paymentDetails?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
  statement?: QuestionType.PICK_ARRAY_OF_DTO; // private
  recommendations?: QuestionType.PICK_ARRAY_OF_DTO; // private
  sowe?: QuestionType.PICK_ARRAY_OF_DTO; // sowe
  bankFilesAndOther?: QuestionType.PICK_ARRAY_OF_DTO;
};

type BankContactFormType = TDtoWrapper<
  BankContactDataPartType,
  BankContactHelpPartType
>;

const yupRequiredField = yup.string().required('common:thisIsRequiredField');

const BankContactForm: BankContactFormType = {
  nickname: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:bank.inputs.branchOfficialName.label',
    required: true,
    value: '',
    yupScheme: yupRequiredField,
  },
  selectedCountry: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:common.inputs.selectedCountry.label',
    items: CountriesList,
    withSearch: true,
    required: true,
    value: '',
    yupScheme: yupRequiredField,
  },
  bankAddress: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:bank.inputs.bankAddress.label',
    required: true,
    value: '',
    yupScheme: yupRequiredField,
  },
  //
  bankName: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:bank.inputs.bankName.label',
  },
  bankCity: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:bank.inputs.bankCity.label',
  },
  bankReliability: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:bank.inputs.bankReliability.label',
    items: HighMediumLowList,
  },
  bankConvenience: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:bank.inputs.bankConvenience.label',
    items: HighMediumLowList,
  },
  bankLegalName: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:bank.inputs.bankLegalName.label',
  },
  swiftOrBic: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:common.inputs.swiftOrBic.label',
  },
  bic: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:common.inputs.bic.label',
  },
  sepa: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:common.inputs.sepa.label',
  },
  contactPerson: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:common.inputs.contactPerson.label',
    items: 'contacts',
    dtoType: ContactType.BANK,
  },
  businessCard: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:common.inputs.businessCard.label',
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  // documents
  contract: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:common.inputs.contract.label',
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  paymentDetails: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:common.inputs.paymentDetails.label',
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  statement: {
    type: QuestionType.PICK_ARRAY_OF_DTO, // private,
    label: 'forms_contacts:common.inputs.statement.label',
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  recommendations: {
    type: QuestionType.PICK_ARRAY_OF_DTO, // private,
    label: 'forms_contacts:common.inputs.recommendations.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  sowe: {
    type: QuestionType.PICK_ARRAY_OF_DTO, // sowe,
    label: 'forms_contacts:common.inputs.sowe.label',
    items: 'sowe',
    dtoType: SoweType.SOWE,
  },
  bankFilesAndOther: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:bank.inputs.bankFilesAndOther.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_contacts:common.inputs.comments.label',
  },
};

export { BankContactForm };
