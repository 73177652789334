import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import {
  HighMediumLowList,
  HighMediumLowType,
} from '@/shared/lib/sj-orm/models/asset.dto';
import { DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  ContactDto,
  ContactType,
} from '@/shared/lib/sj-orm/models/contact.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import { PrivateDocumentCategory } from '../../sj-orm/models/document/private.document.dto';
import { SoweType } from '../../sj-orm/models/sowe/sowe.dto';

export enum ValidUntilType {
  UpToThreeMonths = 'UpToThreeMonths',
  UpToSixMonths = 'UpToSixMonths',
  Annual = 'Annual', // 12 months
  SomeYears = 'SomeYears',
}

type BrokerContactDataPartType = Omit<ContactDto, 'id' | 'type'> & {
  selectedCountry: string;
  brokerAddress: string;
  nickname: string;
  //
  brokerName?: string;
  brokerCity?: string;
  brokerReliability?: HighMediumLowType;
  brokerConvenience?: HighMediumLowType;
  brokerLegalName?: string;
  swiftOrBic?: string;
  bic?: string;
  sepa?: string;
  contactPerson?: DtoId;
  businessCard?: DtoId;
  // documents
  contract?: DtoId;
  paymentDetails?: DtoId;
  comments?: string;
  statement?: DtoId; // private
  recommendations?: DtoId[]; // private
  sowe?: DtoId; // sowe
  brokerFilesAndOther?: DtoId[];
};

type BrokerContactHelpPartType = {
  selectedCountry: QuestionType.SELECT_FROM_LIST;
  brokerAddress: QuestionType.TEXT;
  nickname: QuestionType.TEXT;
  //
  brokerName?: QuestionType.TEXT;
  brokerCity?: QuestionType.TEXT;
  brokerReliability?: QuestionType.SELECT_FROM_LIST;
  brokerConvenience?: QuestionType.SELECT_FROM_LIST;
  brokerLegalName?: QuestionType.TEXT;
  swiftOrBic?: QuestionType.TEXT;
  bic?: QuestionType.TEXT;
  sepa?: QuestionType.TEXT;
  contactPerson?: QuestionType.PICK_ARRAY_OF_DTO;
  businessCard?: QuestionType.PICK_ARRAY_OF_DTO;
  // documents
  contract?: QuestionType.PICK_ARRAY_OF_DTO;
  paymentDetails?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
  statement?: QuestionType.PICK_ARRAY_OF_DTO; // private
  recommendations?: QuestionType.PICK_ARRAY_OF_DTO; // private
  sowe?: QuestionType.PICK_ARRAY_OF_DTO; // sowe
  brokerFilesAndOther?: QuestionType.PICK_ARRAY_OF_DTO;
};

type BrokerContactFormType = TDtoWrapper<
  BrokerContactDataPartType,
  BrokerContactHelpPartType
>;

const yupRequiredField = yup.string().required('common:thisIsRequiredField');

const BrokerContactForm: BrokerContactFormType = {
  nickname: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:broker.inputs.brokerOfficialName.label',
    yupScheme: yupRequiredField,
    required: true,
    value: '',
  },
  selectedCountry: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:common.inputs.selectedCountry.label',
    items: CountriesList,
    withSearch: true,
    yupScheme: yupRequiredField,
    required: true,
    value: '',
  },
  brokerAddress: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:broker.inputs.brokerAddress.label',
    yupScheme: yupRequiredField,
    required: true,
    value: '',
  },
  //
  brokerName: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:broker.inputs.brokerName.label',
  },
  brokerCity: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:broker.inputs.brokerCity.label',
  },
  brokerReliability: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:broker.inputs.brokerReliability.label',
    items: HighMediumLowList,
  },
  brokerConvenience: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:broker.inputs.brokerConvenience.label',
    items: HighMediumLowList,
  },
  brokerLegalName: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:broker.inputs.brokerLegalName.label',
  },
  swiftOrBic: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:common.inputs.swiftOrBic.label',
  },
  bic: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:common.inputs.bic.label',
  },
  sepa: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:common.inputs.sepa.label',
  },
  contactPerson: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:common.inputs.contactPerson.label',
    items: 'contacts',
    dtoType: ContactType.BANK,
  },
  businessCard: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:common.inputs.businessCard.label',
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  // documents
  contract: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:common.inputs.contract.label',
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  paymentDetails: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:common.inputs.paymentDetails.label',
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  statement: {
    type: QuestionType.PICK_ARRAY_OF_DTO, // private,
    label: 'forms_contacts:common.inputs.statement.label',
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  recommendations: {
    type: QuestionType.PICK_ARRAY_OF_DTO, // private,
    label: 'forms_contacts:common.inputs.recommendations.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  sowe: {
    type: QuestionType.PICK_ARRAY_OF_DTO, // sowe,
    label: 'forms_contacts:common.inputs.sowe.label',
    items: 'sowe',
    dtoType: SoweType.SOWE,
  },
  brokerFilesAndOther: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:broker.inputs.brokerFilesAndOther.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_contacts:common.inputs.comments.label',
  },
};

export { BrokerContactForm };
