import { FormItemLabelType } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';

export type AssetDto = BaseDto & {
  name?: string;
  nickName?: string;
  nickname?: string;
  category: AssetCategory;
  type?: string;
  assignedCountry?: string;
  country?: string;
  countryOfAcquisition?: string;
  holder?: string;
  purchaseValue?: {
    calculatedValue: string;
    from: string;
    value: number;
  };
  bank?: string;
  dateOfOpening?: string;
  isTest?: boolean;
  //purchaseCurrencyValue?: string;
  //currencyReverseRate?: string;

  //formId?: DtoId;
  //scenarioId?: DtoId;
} & AssetsStatisticsPart &
  AssetsOwnershipPart;

export enum AssetStatus {
  NEW = 'NEW',
  INCOMPLETE = 'INCOMPLETE',
  FILLED = 'FILLED',
}

export enum AssetsType {
  MOVABLE = 'MOVABLE',
  IMMOVABLE = 'IMMOVABLE',
  CRYPTO = 'CRYPTO',
}

export enum AssetCategory {
  DEBIT_ACCOUNTS = 'DEBIT_ACCOUNTS',
  RESIDENTIAL_REAL_ESTATE = 'RESIDENTIAL_REAL_ESTATE',
  COMMERCIAL_REAL_ESTATE = 'COMMERCIAL_REAL_ESTATE',
  LAND = 'LAND',
  LEGAL_ENTITIES = 'LEGAL_ENTITIES',
  JEWELLERY = 'JEWELLERY',
  BANK_CELLS = 'BANK_CELLS',
  METAL_ACCOUNTS = 'METAL_ACCOUNTS',
  CENTRALIZED_WALLET = 'CENTRALIZED_WALLET',
  DECENTRALIZED_WALLET = 'DECENTRALIZED_WALLET',
  PRECIOUS_STONES = 'PRECIOUS_STONES',
  COMMODITY_ASSET_TOKENS = 'COMMODITY_ASSET_TOKENS',
  BROKERAGE_ACCOUNT = 'BROKERAGE_ACCOUNT',
  SECURITIES_AND_STOCKS = 'SECURITIES_AND_STOCKS',
  BONDS = 'BONDS',
  EXCHANGE_OPTIONS = 'EXCHANGE_OPTIONS',
  OVER_THE_COUNTER_OPTIONS = 'OVER_THE_COUNTER_OPTIONS',
  FORWARDS = 'FORWARDS',
  FUTURES = 'FUTURES',
  MY_FUND = 'MY_FUND',
  PUBLIC_FUND = 'PUBLIC_FUND',
}

////////////////////////////////////////////////////////////////////////////////

export const isAssetCategory = (category: string): category is AssetCategory =>
  Object.values(AssetCategory).includes(category as AssetCategory);

export enum ActivityType {
  active = 'active',
  passive = 'passive',
}

export const ActivityList = [
  {
    key: ActivityType.active,
    label: 'forms_assets:common.enums.activity.active',
  },
  {
    key: ActivityType.passive,
    label: 'forms_assets:common.enums.activity.passive',
  },
];

export enum InvestmentsGoalsType {
  investment = 'investment',
  personal = 'personal',
  saving = 'saving',
}

export const InvestmentsGoalsList = [
  {
    label: 'forms_assets:common.enums.goal.investment',
    key: InvestmentsGoalsType.investment,
  },
  {
    label: 'forms_assets:common.enums.goal.personal',
    key: InvestmentsGoalsType.personal,
  },
  {
    label: 'forms_assets:common.enums.goal.saving',
    key: InvestmentsGoalsType.saving,
  },
];

export enum PerspectivesType {
  sell = 'sell',
  onHold = 'onHold',
  develop = 'develop',
}

export const PerspectivesList = [
  {
    label: 'forms_assets:common.enums.outlook.sell',
    key: PerspectivesType.sell,
  },
  {
    label: 'forms_assets:common.enums.outlook.onHold',
    key: PerspectivesType.onHold,
  },
  {
    label: 'forms_assets:common.enums.outlook.develop',
    key: PerspectivesType.develop,
  },
];

export enum HighMediumLowType {
  high = 'high',
  medium = 'medium',
  low = 'low',
}

export const HighMediumLowList = [
  {
    label: 'forms_assets:common.enums.highMediumLow.high',
    key: HighMediumLowType.high,
  },
  {
    label: 'forms_assets:common.enums.highMediumLow.medium',
    key: HighMediumLowType.medium,
  },
  {
    label: 'forms_assets:common.enums.highMediumLow.low',
    key: HighMediumLowType.low,
  },
];

export enum TaxAgentType {
  client = 'client',
  bank = 'bank',
  other = 'other',
}

export const TaxAgentList = [
  {
    label: 'forms_assets:debitAccount.enums.taxAgent.client',
    key: TaxAgentType.client,
  },
  {
    label: 'forms_assets:debitAccount.enums.taxAgent.bank',
    key: TaxAgentType.bank,
  },
  {
    label: 'forms_assets:debitAccount.enums.taxAgent.other',
    key: TaxAgentType.other,
  },
];

export enum OwnershipType {
  DIRECT = 'DIRECT',
  INDIRECT = 'INDIRECT',
  JOINT = 'JOINT',
}

export const OwnershipList = [
  {
    label: 'forms_assets:common.enums.ownershipType.direct',
    key: OwnershipType.DIRECT,
  },
  {
    label: 'forms_assets:common.enums.ownershipType.indirect',
    key: OwnershipType.INDIRECT,
  },
  // {
  //   label: 'forms_assets:common.enums.ownershipType.joint',
  //   key: OwnershipType.JOINT,
  // },
];

export enum DisclosureType {
  yes = 'yes',
  done = 'done',
  no = 'no',
  inProcess = 'inProcess',
}

export const InformationDisclosureList = [
  {
    label: 'forms_assets:common.enums.informationDisclosure.yes',
    key: DisclosureType.yes,
  },
  {
    label: 'forms_assets:common.enums.informationDisclosure.done',
    key: DisclosureType.done,
  },
  {
    label: 'forms_assets:common.enums.informationDisclosure.no',
    key: DisclosureType.no,
  },
  {
    label: 'forms_assets:common.enums.informationDisclosure.inProcess',
    key: DisclosureType.inProcess,
  },
];

export enum PublicFundTaxAgentType {
  investor = 'investor',
  fund = 'fund',
  other = 'other',
}

export const PublicFundTaxAgentList = [
  {
    label: 'forms_assets:publicFund.enums.taxAgent.investor',
    key: PublicFundTaxAgentType.investor,
  },
  {
    label: 'forms_assets:publicFund.enums.taxAgent.fund',
    key: PublicFundTaxAgentType.fund,
  },
  {
    label: 'forms_assets:publicFund.enums.taxAgent.other',
    key: PublicFundTaxAgentType.other,
  },
];

export enum TaxAgentWithBrokerType {
  client = 'client',
  broker = 'broker',
  other = 'other',
}

export const TaxAgentWithBrokerList = [
  {
    label: 'forms_assets:common.enums.taxAgentWithBroker.client',
    key: TaxAgentWithBrokerType.client,
  },
  {
    label: 'forms_assets:common.enums.taxAgentWithBroker.broker',
    key: TaxAgentWithBrokerType.broker,
  },
  {
    label: 'forms_assets:common.enums.taxAgentWithBroker.other',
    key: TaxAgentWithBrokerType.other,
  },
];

export type AssetsStatisticsPart = Omit<BaseDto, 'id'> & {
  taxBurden?: HighMediumLowType;
  taxAgent?: TaxAgentType;
  taxAgentOther?: string;
  publicFundTaxAgent?: string;
  publicTaxAgentOther?: string;
  taxAgentWithBroker?: string;
  taxAgentWithBrokerOther?: string;
  limitations?: boolean;
  limitationsComments?: string;
  myTodo?: boolean;
  myTodoComments?: string;
  liquidity?: HighMediumLowType;
  activity?: ActivityType;
  investmentsGoals?: InvestmentsGoalsType;
  outlook?: PerspectivesType;
  riskRate?: HighMediumLowType;
  informationDisclosure?: DisclosureType;
  informationDisclosureDetails?: string;
  specificsOfTaxation?: string;
  profitability?: number;
  profitabilityPlan?: number;
  profitabilityFact?: number;
  commentsOnTheAsset?: string;
};

export type AssetsOwnershipPart = Omit<BaseDto, 'id'> & {
  ownerShipType?: OwnershipType;
  // owner?: DtoId;
  // coOwner?: DtoId[];
  coOwnerDocument?: DtoId[];
  coOwnerShare?: number[];
  isWithCoOwners?: boolean;
  ownerShare?: number;
  coOwners?: {
    // coOwner: DtoId;
    coOwnerDocument: DtoId[];
    coOwnerShare: number;
  }[];
  ownerDocument?: DtoId[];
  // otherOwnerDocuments?: DtoId[];
  contactInfoLabel?: FormItemLabelType;
  contactInfoLabelDescription?: FormItemLabelType;
  address?: string;
  phoneNumber?: {
    Dropdown?: string;
    Input?: string;
  };
  email?: string;
};
