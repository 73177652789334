import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';

import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  ContactDto,
  ContactType,
} from '@/shared/lib/sj-orm/models/contact.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum MetalAccountType {
  gold = 'gold',
  silver = 'silver',
  platinum = 'platinum',
  other = 'other',
}

const MetalAccountTypeList = [
  {
    label: 'forms_assets:metalAccount.enums.metalType.gold',
    key: MetalAccountType.platinum,
  },
  {
    label: 'forms_assets:metalAccount.enums.metalType.silver',
    key: MetalAccountType.gold,
  },
  {
    label: 'forms_assets:metalAccount.enums.metalType.platinum',
    key: MetalAccountType.silver,
  },
  {
    label: 'forms_assets:metalAccount.enums.metalType.other',
    key: MetalAccountType.other,
  },
];

type MetalAccountPart1Type = Omit<BaseDto, 'id'> & {
  nickname: string;
  country: string;
  type: MetalAccountType;
  otherType: string;
  purchaseValue: number;
  holder: DtoId[];
};

type MetalAccountPart2Type = Omit<BaseDto, 'id'> & {
  currentMarketValue?: number;
  cif?: string;
  iban?: string;
  dateOfOpening?: Date;
  fixedTermAccount?: boolean;
  activeUntil?: Date;
  metalQuantity?: number;
  powersOfAttorney?: DtoId[];
  otherDocuments?: DtoId[];
  comments?: string;
};

type MetalAccountHelpPart1Type = {
  nickname: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  type: QuestionType.SELECT_FROM_LIST;
  otherType: QuestionType.TEXT;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  holder: QuestionType.PICK_ARRAY_OF_DTO;
};

type MetalAccountHelpPart2Type = {
  currentMarketValue?: QuestionType.CURRENCY_CALCULATOR;
  cif?: QuestionType.TEXT;
  iban?: QuestionType.TEXT;
  dateOfOpening?: QuestionType.DATE_TIME;
  fixedTermAccount?: QuestionType.CHECKBOX;
  activeUntil?: QuestionType.DATE_TIME;
  metalQuantity?: QuestionType.NUMBER;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
};

type MetalAccountFormPart1Type = TDtoWrapper<
  MetalAccountPart1Type,
  MetalAccountHelpPart1Type
>;
type MetalAccountFormPart2Type = TDtoWrapper<
  MetalAccountPart2Type,
  MetalAccountHelpPart2Type
>;

type MetalAccountFormType = [
  MetalAccountFormPart1Type,
  MetalAccountFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

const MetalAccountFormPart1: MetalAccountFormPart1Type = {
  nickname: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    items: CountriesList,
    value: '',
    required: true,
    withSearch: true,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:metalAccount.inputs.type.label',
    items: MetalAccountTypeList,
    value: MetalAccountType.platinum,
    required: true,
  },
  otherType: {
    type: QuestionType.TEXT,
    label: 'forms_assets:metalAccount.inputs.otherType.label',
    showIf: {
      type: {
        value: MetalAccountType.other,
      },
    },
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:metalAccount.inputs.marketPrice.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  holder: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:metalAccount.inputs.holder.label',
    items: 'contacts',
    value: [],
    tooltip: 'forms_assets:metalAccount.inputs.holder.tooltip',
    required: true,
    filter: (contact) =>
      (contact as unknown as ContactDto).type === ContactType.BANK,
    dtoType: ContactType.BANK,
  },
};

const MetalAccountFormPart2: MetalAccountFormPart2Type = {
  currentMarketValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:metalAccount.inputs.purchaseValue.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 1,
  },
  cif: {
    type: QuestionType.TEXT,
    label: 'forms_assets:metalAccount.inputs.cif.label',
    order: 2,
  },
  iban: {
    type: QuestionType.TEXT,
    label: 'forms_assets:debitAccount.inputs.iban.label',
    order: 3,
  },
  dateOfOpening: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:debitAccount.inputs.dateOfOpening.label',
    order: 4,
  },
  fixedTermAccount: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:debitAccount.inputs.fixedTermAccount.label',
    order: 5,
  },
  activeUntil: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:debitAccount.inputs.activeUntil.label',
    tooltip: 'forms_assets:metalAccount.inputs.activeUntil.tooltip',
    showIf: {
      fixedTermAccount: {
        value: true,
      },
    },
    order: 6,
  },
  metalQuantity: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:metalAccount.inputs.metalQuantity.label',
    order: 7,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory ===
      PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  otherDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherDocuments.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

const MetalAccountForm: MetalAccountFormType = [
  MetalAccountFormPart1,
  MetalAccountFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgentWithBroker',
    'taxAgentWithBrokerOther',
    'publicFundTaxAgent',
    'publicTaxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];

export { MetalAccountForm };
