import { DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { DocumentBaseDto } from '@/shared/lib/sj-orm/models/document/document-base.dto';

export interface PersonalIdentifierDocumentDto extends DocumentBaseDto {
  personalIdentifierType: PersonalIdentifierType;
  name?: string;
  country?: string;
  commentary?: string;
  scan?: string;
  assetId?: DtoId;
  beneficiaryId?: DtoId;
}

export enum PersonalIdentifierType {
  // OWN_ID = 'OWN_ID',
  BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  EDUCATION = 'EDUCATION',
  FREE_BLOCK = 'FREE_BLOCK',
  INTERNATIONAL_DRIVER_LICENSE = 'INTERNATIONAL_DRIVER_LICENSE',
  MEDICAL_ID = 'MEDICAL_ID',
  MILITARY_ID = 'MILITARY_ID',
  NATIONAL_ID = 'NATIONAL_ID',
  PASSPORT = 'PASSPORT',
  PERSONAL_CONTACTS = 'PERSONAL_CONTACTS',
  RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
  SOCIAL_SECURITY = 'SOCIAL_SECURITY',
  SUBSTANCE = 'SUBSTANCE',
  TAX_ID = 'TAX_ID',
  USER_DETAILS = 'USER_DETAILS',
}

export const isPersonalIdentifierType = (
  type: string,
): type is PersonalIdentifierType =>
  Object.values(PersonalIdentifierType).includes(
    type as PersonalIdentifierType,
  );
