import { ReactNode } from 'react';
import { RelatingExpression } from 'src/shared/_legacy_/condition-utils';
import { StringSchema } from 'yup';

import {
  CollectionName,
  CollectionsTypeValueMap,
  ICollections,
} from '@/shared/lib/sj-orm/constants';
import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';
import { ArrayOrOne } from '@/shared/types/utility-types';

//todo think about styles

/**
 * css example
 *
 * import styles from './styles.module.css'
 * {
 *   overrideStyle: styles.input
 * }
 *
 * /////
 *
 * styled-components example
 *
 * {
 *   overrideComponent: (...args: unknown[]) => <Input {...args} />
 * }
 *
 * **/

type TI18Alias = string;

type TSelectItem = {
  key: string;
  label: string;
  icon?: ReactNode;
};

export type ValueType<
  TQuestionType extends HelperRenderType,
  TDtoContext,
  TValueType = unknown,
> = {
  label:
    | TI18Alias
    | {
        prefix: string;
        postfix: string;
        fieldKey: string;
      };
  placeholder?: string;
  showIf?: RelatingExpression<TDtoContext>;
  yupScheme?: StringSchema;
  order?: number;
  tooltip?: string;
  equivalentValueTooltip?: string;
  withSearch?: boolean;
  // only works for default inputs and pick array of dto
  deps?: Array<string>;
} & (
  | { required?: true; value: TValueType }
  | { required?: false; value?: TValueType }
) &
  (
    | {
        type: QuestionType.RENDER_NEW_BUTTON;
        label: TI18Alias;
        renderItems: string[];
        uniqKeyPrefix: string; // should be uniq for react key properties
      }
    | {
        type: QuestionType.CURRENCY_AMOUNT | QuestionType.TIME_DURATION;
        items: TSelectItem[];
      }
    | {
        type:
          | QuestionType.SELECT_FROM_LIST
          | QuestionType.CURRENCY_AMOUNT
          | QuestionType.TIME_DURATION;
        items: TSelectItem[];
        mode?: 'multiple' | 'tags';
      }
    | {
        type: QuestionType.PICK_ARRAY_OF_DTO;
        items: ArrayOrOne<keyof ICollections>;
        mode?: 'multiple' | 'tags';
        filter?: <
          K extends CollectionName,
          T extends CollectionsTypeValueMap[K],
        >(
          item: T,
        ) => boolean;
        dtoType?: string;
      }
    | {
        type: Exclude<
          TQuestionType,
          | QuestionType.SELECT_FROM_LIST
          | QuestionType.CURRENCY_AMOUNT
          | QuestionType.TIME_DURATION
          | QuestionType.PICK_ARRAY_OF_DTO
          | QuestionType.RENDER_NEW_BUTTON
          | FormLabelItem
        >;
      }
  );

export enum FormLabelItem {
  h1 = 'h1',
  text = 'text',
  label = 'label',
}

export const LABELS_ARR = [
  FormLabelItem.h1,
  FormLabelItem.text,
  FormLabelItem.label,
];

export const isLabels = (item: unknown): item is FormLabelItem =>
  LABELS_ARR.includes(<FormLabelItem>item);

export type FormItemLabelType<TDtoContext = unknown> = {
  type: FormLabelItem;
  label:
    | {
        prefix: string;
        postfix: string;
        fieldKey: string;
        formator?: (text: string) => string;
      }
    | string;
  order?: number;
  showIf?: RelatingExpression<TDtoContext>;
  tooltip?: string;
};

export type HelperRenderType = QuestionType | FormLabelItem;

export type OmitedBaseDto = Omit<BaseDto, 'id'>;

export type TDtoWrapper<
  T extends OmitedBaseDto,
  TQuestionType extends { [TK in keyof T]: QuestionType },
> = {
  [K in keyof T]: ValueType<
    TQuestionType[K],
    TDtoWrapper<T, TQuestionType>,
    T[K]
  >;
};
