import { RcFile } from 'antd/es/upload';

import { CryptoCore } from '@/shared/lib/secure-json/core/crypto-core';
import { KeyPair } from '@/shared/lib/secure-json/core/crypto-core/types';

const DEFAULT_RANDOM_MAX = 100;
const DEFAULT_SLEEP_MS = 2000;
export const DEFAULT_SLEEP_MS_DELAY = 4000;
export const MS_IN_A_DAY = 86400000;

export const copy = (text: string | undefined) =>
  navigator.clipboard.writeText(text ?? '');

export const getRandomInt = (max: number = DEFAULT_RANDOM_MAX) =>
  Math.floor(Math.random() * max);

export const sleep = (ms: number = DEFAULT_SLEEP_MS) =>
  new Promise<void>((resolve) => setTimeout(resolve, ms));

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const shuffle = (array: string[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // eslint-disable-next-line security/detect-object-injection
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const createObjectByKeys = <TOutputValue>(
  keys: Array<string>,
  defaultValue: TOutputValue,
): { [key: string]: TOutputValue } => {
  const obj = {};
  // eslint-disable-next-line guard-for-in
  for (const key of keys) {
    Object.assign(obj, { [key]: defaultValue });
  }
  return obj;
};

export const getKeyPairFromMnemonic = async (
  phrase: string,
  password: string,
): Promise<KeyPair> => {
  const cryptoCore = new CryptoCore();
  const mnemonic = cryptoCore.aes.decrypt(phrase, password);
  return await cryptoCore.mnemonic.mnemonicToKeyPair(mnemonic);
};

export const getDecryptedMnemonicPhrase = async (
  encryptedPhrase: string,
  password: string,
): Promise<string> => {
  const cryptoCore = new CryptoCore();
  return cryptoCore.aes.decrypt(encryptedPhrase, password);
};

export const randomString = (length: number = 2): string => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export function capitalizeFirstLetter(string: string | undefined) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

export function lowerTextAndCapitalizeFirstLetter(string: string | undefined) {
  return string
    ? string.charAt(0) + string.slice(1).split('_').join(' ').toLowerCase()
    : '';
}
export function capSplit(string: string) {
  return string.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
    return str.toUpperCase();
  });
}

export function daysToTimestamp(days: number | undefined) {
  return days ? days * MS_IN_A_DAY : 0;
}

export function timestampToDays(days: number | undefined) {
  return days ? days / MS_IN_A_DAY : 0;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce<T extends Function>(
  func: T,
  wait = 2_000,
  immediate = false,
) {
  let timeout: NodeJS.Timeout | null = null;

  return function executedFunction() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const context = this; // eslint-disable-line @typescript-eslint/no-this-alias,consistent-this
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;

    // eslint-disable-next-line func-style,func-names
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

export const copyAny = <T>(target: T): T => {
  if (Array.isArray(target)) return target.map((i) => copyAny(i)) as T;
  if (typeof target !== 'object' || target === null || target === undefined)
    return target;
  const _target = target as Record<string, unknown>;
  return Object.keys(_target).reduce((carry: Record<string, unknown>, key) => {
    const val = _target[key];
    carry[key] = copyAny(val);
    return carry;
  }, {}) as T;
};

export function isEmptyObject(obj: object, deep = false) {
  // eslint-disable-next-line guard-for-in
  for (const key in obj) {
    return deep && isEmpty((obj as Record<string, unknown>)[key], true);
  }
  return true;
}

export function isEmpty(data: unknown, deep = false): boolean {
  if (Array.isArray(data) || typeof data === 'string') return data.length === 0;

  if (typeof data === 'object') {
    if (data !== null) return isEmptyObject(data, deep);
    return true;
  }

  if (typeof data === 'undefined') return true;

  return !data;
}
