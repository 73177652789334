import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import {
  FormItemLabelType,
  FormLabelItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import { AssetCategory } from '../../sj-orm/models/asset.dto';
import { ContactType } from '../../sj-orm/models/contact.dto';

export type ResidentialRealEstate = ResidentialRealEstateFormPart1Type &
  ResidentialRealEstateFormPart2Type &
  AssetsOwnershipPartType &
  AssetsStatisticsPartType;

export enum ResidentialRealEstateType {
  flat = 'flat',
  studio = 'studio',
  apartment = 'apartment',
  condominium = 'condominium',
  house = 'house',
  cottage = 'cottage',
  duplex = 'duplex',
  bungalow = 'bungalow',
  chalet = 'chalet',
  townhouse = 'townhouse',
  ranch = 'ranch',
  penthouse = 'penthouse',
  villa = 'villa',
  mansion = 'mansion',
  castle = 'castle',
  estate = 'estate',
}

const ResidentialRealEstateList = [
  {
    label: 'forms_assets:residentialRealEstate.enums.type.flat',
    key: ResidentialRealEstateType.flat,
  },
  {
    label: 'forms_assets:residentialRealEstate.enums.type.studio',
    key: ResidentialRealEstateType.studio,
  },
  {
    label: 'forms_assets:residentialRealEstate.enums.type.apartment',
    key: ResidentialRealEstateType.apartment,
  },
  {
    label: 'forms_assets:residentialRealEstate.enums.type.condominium',
    key: ResidentialRealEstateType.condominium,
  },
  {
    label: 'forms_assets:residentialRealEstate.enums.type.house',
    key: ResidentialRealEstateType.house,
  },
  {
    label: 'forms_assets:residentialRealEstate.enums.type.cottage',
    key: ResidentialRealEstateType.cottage,
  },
  {
    label: 'forms_assets:residentialRealEstate.enums.type.duplex',
    key: ResidentialRealEstateType.duplex,
  },
  {
    label: 'forms_assets:residentialRealEstate.enums.type.bungalow',
    key: ResidentialRealEstateType.bungalow,
  },
  {
    label: 'forms_assets:residentialRealEstate.enums.type.chalet',
    key: ResidentialRealEstateType.chalet,
  },
  {
    label: 'forms_assets:residentialRealEstate.enums.type.townhouse',
    key: ResidentialRealEstateType.townhouse,
  },
  {
    label: 'forms_assets:residentialRealEstate.enums.type.ranch',
    key: ResidentialRealEstateType.ranch,
  },
  {
    label: 'forms_assets:residentialRealEstate.enums.type.penthouse',
    key: ResidentialRealEstateType.penthouse,
  },
  {
    label: 'forms_assets:residentialRealEstate.enums.type.villa',
    key: ResidentialRealEstateType.villa,
  },
  {
    label: 'forms_assets:residentialRealEstate.enums.type.mansion',
    key: ResidentialRealEstateType.mansion,
  },
  {
    label: 'forms_assets:residentialRealEstate.enums.type.castle',
    key: ResidentialRealEstateType.castle,
  },
  {
    label: 'forms_assets:residentialRealEstate.enums.type.estate',
    key: ResidentialRealEstateType.estate,
  },
];

type ResidentialRealEstatePart1 = Omit<BaseDto, 'id'> & {
  nickName: string;
  country: string;
  type: ResidentialRealEstateType;
  purchaseValue: number;
  addressLabel?: FormItemLabelType;
  district: string;
  zipCode: string;
  city: string;
  street: string;
  buildingNumber: string;
  apartmentNumber?: string;
};

type ResidentialRealEstatePart2 = Omit<BaseDto, 'id'> & {
  residentialRealEstateNumberName?: string;
  residentialRealEstateNumber?: string;
  // otherAddressInfo?: string;
  commencementDate?: string;
  areaOfTheApartments?: number;
  compositionOfObjects?: string;
  landPlot?: boolean;
  powersOfAttorney?: DtoId[];
  otherDocuments?: DtoId[];
  chooseLandPlot?: DtoId[];
  landPlotSquare?: number;
  contacts?: DtoId[];
  comments?: string;
};

type ResidentialRealEstateHelpPart1 = {
  nickName: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  type: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  district: QuestionType.TEXT;
  zipCode: QuestionType.TEXT;
  city: QuestionType.TEXT;
  street: QuestionType.TEXT;
  buildingNumber: QuestionType.TEXT;
  apartmentNumber: QuestionType.TEXT;
};

type ResidentialRealEstateHelpPart2 = {
  residentialRealEstateNumberName?: QuestionType.TEXT;
  residentialRealEstateNumber?: QuestionType.TEXT;
  // otherAddressInfo?: QuestionType.TEXT_MULTILINE;
  constructionDate?: QuestionType.DATE_TIME;
  apartmentSquare?: QuestionType.NUMBER;
  compositionOfObjects?: QuestionType.TEXT;
  landPlot?: QuestionType.CHECKBOX;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  chooseLandPlot?: QuestionType.PICK_ARRAY_OF_DTO;
  landPlotSquare?: QuestionType.NUMBER;
  contacts?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
};

type ResidentialRealEstateFormType = [
  ResidentialRealEstateFormPart1Type,
  ResidentialRealEstateFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

type ResidentialRealEstateFormPart1Type = TDtoWrapper<
  ResidentialRealEstatePart1,
  ResidentialRealEstateHelpPart1
>;

type ResidentialRealEstateFormPart2Type = TDtoWrapper<
  ResidentialRealEstatePart2,
  ResidentialRealEstateHelpPart2
>;

const ResidentialRealEstateFormPart1: ResidentialRealEstateFormPart1Type = {
  nickName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:residentialRealEstate.inputs.type.label',
    value: ResidentialRealEstateType.apartment,
    required: true,
    items: ResidentialRealEstateList,
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:residentialRealEstate.inputs.currentMarketPrice.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  addressLabel: {
    type: FormLabelItem.label,
    label: 'forms_assets:common.inputs.addressLabel.label',
    tooltip: 'forms_assets:common.inputs.addressLabel.tooltip',
  },
  district: {
    type: QuestionType.TEXT,
    label: {
      prefix: '*',
      fieldKey: '',
      postfix: 'forms_assets:residentialRealEstate.inputs.district.label',
    },
    required: true,
    value: '',
  },
  zipCode: {
    type: QuestionType.TEXT,
    label: {
      prefix: '*',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.zipCode.label',
    },
    required: true,
    value: '',
  },
  city: {
    type: QuestionType.TEXT,
    label: {
      prefix: '*',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.city.label',
    },
    required: true,
    value: '',
  },
  street: {
    type: QuestionType.TEXT,
    label: {
      prefix: '*',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.street.label',
    },
    required: true,
    value: '',
  },
  buildingNumber: {
    type: QuestionType.TEXT,
    label: {
      prefix: '*',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.buildingNumber.label',
    },
    required: true,
    value: '',
  },
  apartmentNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.apartment.label',
    value: '',
  },
};

const ResidentialRealEstateFormPart2: ResidentialRealEstateFormPart2Type = {
  residentialRealEstateNumberName: {
    type: QuestionType.TEXT,
    label:
      'forms_assets:residentialRealEstate.inputs.residentialRealEstateNumberName.label',
    order: 1,
  },
  residentialRealEstateNumber: {
    type: QuestionType.TEXT,
    label:
      'forms_assets:residentialRealEstate.inputs.residentialRealEstateNumber.label',
    order: 2,
  },
  // otherAddressInfo: {
  //   type: QuestionType.TEXT_MULTILINE,
  //   label: 'forms_assets:residentialRealEstate.inputs.otherAddressInfo.label',
  // },
  commencementDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:residentialRealEstate.inputs.commencementDate.label',
    tooltip:
      'forms_assets:residentialRealEstate.inputs.commencementDate.tooltip',
    order: 3,
  },
  areaOfTheApartments: {
    type: QuestionType.NUMBER,
    label:
      'forms_assets:residentialRealEstate.inputs.areaOfTheApartments.label',
    order: 4,
  },
  compositionOfObjects: {
    type: QuestionType.TEXT,
    label:
      'forms_assets:residentialRealEstate.inputs.compositionOfObjects.label',
    order: 5,
  },
  landPlot: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:residentialRealEstate.inputs.landPlot.label',
    order: 6,
  },
  chooseLandPlot: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:residentialRealEstate.inputs.chooseLandPlot.label',
    items: 'assets',
    // filter: (asset: AssetDto) => asset.category === AssetCategory.LAND,
    showIf: {
      landPlot: {
        value: true,
      },
    },
    order: 7,
    dtoType: AssetCategory.LAND,
  },
  landPlotSquare: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:residentialRealEstate.inputs.landPlotSquare.label',
    showIf: {
      landPlot: {
        value: true,
      },
    },
    order: 8,
  },
  contacts: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.contacts.label',
    items: 'contacts',
    mode: 'multiple',
    order: 9,
    dtoType: ContactType.BANK,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory ===
      PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  otherDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherDocuments.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

const ResidentialRealEstateForm: ResidentialRealEstateFormType = [
  ResidentialRealEstateFormPart1,
  ResidentialRealEstateFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgentWithBroker',
    'taxAgent',
    'publicFundTaxAgent',
    'taxAgentOther',
    'taxAgentWithBrokerOther',
    'publicTaxAgentOther',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];

export { ResidentialRealEstateForm };
