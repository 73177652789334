import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';

interface IMarginStyles {
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  marginX?: number;
  marginY?: number;
}

interface IPaddingStyles {
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingX?: number;
  paddingY?: number;
}

const customMediaQuery = (minWidth: number) =>
  `@media screen and (min-width: ${minWidth}px)`;

const customMobileMediaQuery = (maxWidth: number) =>
  `@media screen and (max-width: ${maxWidth}px)`;

export const media = {
  desktop: customMediaQuery(992),
  mobile: customMobileMediaQuery(991),
};

export interface IContainer extends IMarginStyles, IPaddingStyles {
  height?: string;
  minHeight?: string;
  position?: string;
  relative?: boolean;
  maxWidth?: number;
  width?: string;
  textCenter?: boolean;
  zIndex?: number;
  maxHeight?: string;
  displayDesktop?: boolean;
  displayMobile?: boolean;
  color?: string;
  colorMobile?: string;
  borderDesktop?: boolean;
  borderMobile?: boolean;
  boxShadowDesktop?: boolean;
  boxShadowMobile?: boolean;
}

interface IRow extends IContainer {
  justifyContent?: string;
  spaceBetween?: boolean;
  alignCenter?: boolean;
  alignItems?: string;
  direction?: string;
  wrap?: string;
  nowrap?: boolean;
  wide?: boolean;
  width?: string;
  gap?: number;
  gapColumn?: number;
  gapRow?: number;
  withBorder?: boolean;
}

interface IPosition extends IContainer {
  position: 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky' | 'unset';
}

export const marginStyles = (p: IMarginStyles) => `
  ${
    p.marginTop || p.marginY ? `margin-top: ${p.marginTop || p.marginY}px;` : ''
  }
  ${
    p.marginBottom || p.marginY
      ? `margin-bottom: ${p.marginBottom || p.marginY}px;`
      : ''
  }
  ${
    p.marginLeft || p.marginX
      ? `margin-left: ${p.marginLeft || p.marginX}px;`
      : ''
  }
  ${
    p.marginRight || p.marginX
      ? `margin-right: ${p.marginRight || p.marginX}px;`
      : ''
  }
`;
export const paddingStyles = (p: IPaddingStyles) => `
    ${
      p.paddingTop || p.paddingY
        ? `padding-top: ${p.paddingTop || p.paddingY}px;`
        : ''
    }
    ${
      p.paddingBottom || p.paddingY
        ? `padding-bottom: ${p.paddingBottom || p.paddingY}px;`
        : ''
    }
    ${
      p.paddingLeft || p.paddingX
        ? `padding-left: ${p.paddingLeft || p.paddingX}px;`
        : ''
    }
    ${
      p.paddingRight || p.paddingX
        ? `padding-right: ${p.paddingRight || p.paddingX}px;`
        : ''
    }
`;

export const Container = styled.div<IContainer>`
  ${(p) => `
    ${marginStyles(p)}
    ${paddingStyles(p)}
    ${p.height ? `height: ${p.height};` : ''}
    ${p.minHeight ? `min-height: ${p.minHeight};` : ''}
    ${p.maxHeight ? `max-height: ${p.maxHeight};` : ''}
    ${p.position || p.relative ? `position: ${p.position || 'relative'};` : ''}
    ${p.maxWidth ? `max-width: ${p.maxWidth}px;` : ''}
    ${p.width ? `width: ${p.width};` : ''}
    ${p.textCenter ? `text-align: center;` : ''}
    ${p.zIndex ? `z-index: ${p.zIndex};` : ''}

    ${p.hidden ? 'display: none;' : ''}
  `}
`;

export const Row = styled(Container)<IRow>`
  display: flex;
  justify-content: ${(p) =>
    p.justifyContent || p.spaceBetween
      ? p.justifyContent || 'space-between'
      : 'unset'};
  align-items: ${(p) =>
    p.alignCenter || p.alignItems ? p.alignItems || 'center' : 'unset'};
  flex-direction: ${(p) => (p.direction ? `${p.direction}` : 'unset')};
  flex-wrap: ${(p) => (p.wrap || p.nowrap ? p.wrap || 'nowrap' : 'wrap')};
  margin-left: ${(p) => (p.gap ? `${-p.gap}px` : '0')};
  margin-right: ${(p) => (p.gap ? `${-p.gap}px` : '0')};
  width: ${(p) => (p.width || p.wide ? p.width || '100%' : 'auto')};

  ${({ gap }) => (gap ? `gap: ${gap}px` : '')};
  ${({ gapColumn }) => (gapColumn ? `column-gap: ${gapColumn}px` : '')};
  ${({ gapRow }) => (gapRow ? `row-gap: ${gapRow}px` : '')};
  ${({ displayDesktop }) => (displayDesktop ? `display: block` : '')};
  ${({ displayMobile }) => (displayMobile ? `display: block` : '')};
`;

export const RowResponsive = styled(Row)<IRow>`
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
  min-height: calc(100dvh - 200px);

  ${media.desktop} {
    margin-top: 60px;
    min-height: calc(100dvh - 200px);
    margin-bottom: 40px;
    flex-wrap: nowrap;
  }
`;

export const RowCommon = styled(Row)<IRow>`
  width: 100%;
  justify-content: center;
  flex-wrap: nowrap;

  ${media.mobile} {
    ${({ withBorder }) =>
      withBorder
        ? `box-shadow:
      0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12),
      0px 6px 16px 0px rgba(0, 0, 0, 0.08);`
        : ''};
    ${({ withBorder }) =>
      withBorder ? `border: 1px solid ${COLORS.colorBorder};` : ''};
    flex-wrap: wrap;
  }
`;

export const ContainerResponsiveInfo = styled(Container)<IContainer>`
  max-width: 416px;
  width: 100%;
  height: 100%;
  position: relative;

  ${media.mobile} {
    max-width: 390px;
    height: auto;
  }
`;
export const ContainerResponsiveInfoInner = styled(Container)<IContainer>`
  padding: 36px 24px 10px 24px;

  ${media.desktop} {
    padding: 32px 40px;
  }
`;
export const ContainerResponsiveContent = styled(Container)<IContainer>`
  max-width: 582px;
  width: 100%;

  ${media.mobile} {
    max-width: 390px;
  }
`;
export const ContainerResponsiveContentInner = styled(Container)<IContainer>`
  padding: 10px 24px 30px 24px;

  ${media.desktop} {
    padding: 32px 40px;
  }
`;
export const Position = styled(Container)<IPosition>`
  ${({ position }) => `position: ${position};`};
`;

export const Header = styled(Container)`
  background-color: ${COLORS.white};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
`;

export const MobileContainer = styled.div`
  ${media.desktop} {
    display: none;
  }
`;

export const DesktopContainer = styled.div`
  display: none;
  ${media.desktop} {
    display: block;
  }
`;
