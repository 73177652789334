import { useCallback, useMemo } from 'react';

import { useCurrenciesListQuery } from '@/shared/generated/graphql';

type CalcCurrencyInputType = {
  from: string;
  to?: string;
  amount: number;
};

type CalculateCurrencyResult = {
  result: 'success' | 'error';
  value: number;
};

export const useCurrencies = () => {
  const { data } = useCurrenciesListQuery();

  const getCurrency = useCallback(
    (symbol: string) => {
      return data?.currencyRates.find(
        (cur) => cur.currencyCode.toLowerCase() === symbol.toLowerCase(),
      );
    },
    [data],
  );

  const calculateCurrency = useCallback(
    ({ from, to, amount }: CalcCurrencyInputType): CalculateCurrencyResult => {
      const curFrom = getCurrency(from);

      if (!curFrom)
        return {
          result: 'error',
          value: 0,
        };

      const _curTo = to ? getCurrency(to) : undefined;

      const curTo = _curTo ? _curTo.currencyCode : curFrom.baseCurrency;

      if (curFrom.currencyCode === curFrom.baseCurrency)
        return {
          value: amount,
          result: 'success',
        };

      if (curTo === curFrom.baseCurrency) {
        return {
          value: amount / curFrom.value,
          result: 'success',
        };
      }

      if (!_curTo)
        return {
          result: 'error',
          value: 0,
        };

      return {
        value: (amount / curFrom.value) * _curTo.value,
        result: 'success',
      };
    },
    [getCurrency, data],
  );

  return useMemo(
    () => ({ list: data?.currencyRates || [], getCurrency, calculateCurrency }),
    [data, getCurrency, calculateCurrency],
  );
};
