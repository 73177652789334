import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Spin } from 'antd';
import { styled } from 'styled-components';

import { isExported } from '@/shared/constants/constants';
import { useWhoamiLazyQuery } from '@/shared/generated/graphql';
import { useAuthStore } from '@/shared/store/auth.store';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { useSettingsStore } from '@/shared/store/settings.store';

export const publicRoutes = [
  '/',
  '',
  'auth',
  '/test/new-form',
  'jyo4h37bm3wrfkrdo9ea02buclok0uj1',
  'sysver',
  'thanks',
];

export default function AuthProvider({ children }: { children: ReactElement }) {
  const { push } = useRouter();
  const { token } = useAuthStore();
  const { encryptedMnemonic } = useSettingsStore();
  const { keyPair } = useKeyPairStore();
  const [loading, setLoading] = useState<boolean>(true);
  const router = useRouter();
  const [whoami] = useWhoamiLazyQuery();

  const pathIsPublic = useMemo(
    () => publicRoutes.includes(router.pathname.split('/')[1]),
    [router.pathname],
  );

  const resolvePath = async () => {
    if (!token && !pathIsPublic && !isExported) {
      localStorage.clear();

      push('/');
      return;
    }

    if (
      (token || isExported) &&
      encryptedMnemonic &&
      !keyPair &&
      (!pathIsPublic ||
        ['/', '/auth/login/enter-pin-code'].includes(router.pathname))
    ) {
      try {
        const resultWhoami = await whoami({
          context: {
            headers: {
              authorization: token,
            },
          },
        });
        if (!resultWhoami.data) {
          push('/');
          localStorage.clear();
          return;
        }
      } catch (error) {
        console.log(error);
        localStorage.clear();
        push('/');
        return;
      }
      push('/auth/login/enter-pin-code');
      return;
    }

    if (isExported && !keyPair) {
      push('/');
      return;
    }

    setLoading(false);
  };

  useEffect(() => {
    resolvePath();
    // if (token && encryptedMnemonic && pathIsPublic) {
    //   push('/account');
    // }
  }, [encryptedMnemonic, keyPair, token, pathIsPublic]);

  if (!pathIsPublic && loading) {
    return (
      <Loader>
        <Spin size={'large'} />
      </Loader>
    );
  }

  return <>{children}</>;
}

const Loader = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
