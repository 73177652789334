import { UserOutlined } from '@ant-design/icons';

import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import {
  BeneficiaryDto,
  BeneficiaryGroup,
} from '@/shared/lib/sj-orm/models/beneficiary.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export const groupToText: Record<BeneficiaryGroup, string> = {
  [BeneficiaryGroup.CLAN]: 'my clan',
  [BeneficiaryGroup.FAMILY]: 'my family',
  [BeneficiaryGroup.CIRCLE]: 'my circle',
};

export type NextOwnerDtoType = Omit<
  BeneficiaryDto,
  'id' | 'centralDbProfileId' | 'linkedCountryCode'
>;

export type NextOwnerDtoHelpType = {
  nickName: QuestionType.TEXT;
  firstName: QuestionType.TEXT;
  lastName: QuestionType.TEXT;
  group: QuestionType.SELECT_FROM_LIST;
  email: QuestionType.EMAIL;
};

type UnilateralFormType = TDtoWrapper<NextOwnerDtoType, NextOwnerDtoHelpType>;

export const NextOwnerCreateForm: UnilateralFormType = {
  nickName: {
    type: QuestionType.TEXT,
    label: '*Next Owner short name',
    required: true,
    value: '',
  },
  firstName: {
    type: QuestionType.TEXT,
    label: '*Next Owner name',
    required: true,
    value: '',
  },
  lastName: {
    type: QuestionType.TEXT,
    label: '*Next Owner short last name',
    required: true,
    value: '',
  },
  group: {
    label: '*Choose group affiliation',
    required: true,
    value: BeneficiaryGroup.FAMILY,
    type: QuestionType.SELECT_FROM_LIST,
    items: [
      {
        label: 'Family',
        key: BeneficiaryGroup.FAMILY,
        icon: <UserOutlined />,
      },
      {
        label: 'Circle',
        key: BeneficiaryGroup.CIRCLE,
        icon: <UserOutlined />,
      },
      {
        label: 'Clan',
        key: BeneficiaryGroup.CLAN,
        icon: <UserOutlined />,
      },
    ],
  },
  email: {
    type: QuestionType.EMAIL,
    label: '*Email',
    required: true,
    value: '',
  },
};
