import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { PersonalIdentifierDocumentDto } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export type PersonalContactsDtoType = Omit<
  PersonalIdentifierDocumentDto,
  'id' | 'personalIdentifierType' | 'type'
> & {
  names: string;
  surnames: string;
  country: string;
  email: string;
  phone: number;
};

export type PersonalContactsDtoHelpType = {
  names: QuestionType.TEXT;
  surnames: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  email: QuestionType.EMAIL;
  phone: QuestionType.PHONE_NUMBER;
};

type PersonalContactsFormType = TDtoWrapper<
  PersonalContactsDtoType,
  PersonalContactsDtoHelpType
>;

export const PersonalContactsForm: PersonalContactsFormType = {
  names: {
    type: QuestionType.TEXT,
    label: '*Name(s)',
    placeholder: 'Fill in as in the document',
    required: true,
    value: '',
  },
  surnames: {
    type: QuestionType.TEXT,
    label: '*Surname(s)',
    placeholder: 'Fill in as in the document',
    required: true,
    value: '',
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: '*Country',
    items: CountriesList,
    withSearch: true,
    required: true,
    value: '',
  },
  email: {
    type: QuestionType.EMAIL,
    label: '*E-mail',
    required: true,
    value: '',
  },
  phone: {
    type: QuestionType.PHONE_NUMBER,
    label: '*Phone',
    required: true,
    value: 0,
  },
};
