import { Account } from '@/shared/generated/graphql';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { BeneficiaryPersonalDataDocumentDto } from '@/shared/lib/sj-orm/models/document/beneficiary-personal-data.document.dto';
import { DocumentType } from '@/shared/lib/sj-orm/models/document/document-base.dto';
import { PersonalIdentifierDocumentDto } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { PrivateDocumentDto } from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { DeepPartial } from '@/shared/types/utility-types';

export interface DelegatingRequestDto extends BaseDto {
  status: DelegatingRequestStatus;
  relatedEntity?: {
    collectionName: CollectionName;
    dtoId: DtoId;
  };
  from: DeepPartial<Account>;
  // todo add DelegatingRequestItemDto types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Record<string, any>[];
}

export type DelegatingRequestItemDto =
  | {
      documentType: DocumentType.PERSONAL_IDENTIFIERS;
      documentDtoIdForReplacing?: DtoId;
      value: PersonalIdentifierDocumentDto;
    }
  | {
      documentType: DocumentType.PRIVATE_DOCUMENTS;
      documentDtoIdForReplacing?: DtoId;
      value: PrivateDocumentDto;
    }
  | {
      documentType: DocumentType.BENEFICIARY_PERSONAL_DATA;
      documentDtoIdForReplacing?: DtoId;
      value: BeneficiaryPersonalDataDocumentDto;
    };

export enum DelegatingRequestStatus {
  OPENED_TO_EDIT = 'OPENED_TO_EDIT',
  WAITING_FOR_APPROVE = 'WAITING_FOR_APPROVE',
  APPROVED = 'APPROVED',
}
