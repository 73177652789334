import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip as AntTooltip } from 'antd';

export const Tooltip = ({ title }: { title: string }): JSX.Element => {
  const parseText = (text: string): JSX.Element[] => {
    const elements: JSX.Element[] = [];
    const lines = text.split('\n');

    lines.forEach((line, index) => {
      const strongRegex = /<strong>(.*?)<\/strong>/g;
      let match;
      let lastIndex = 0;

      while ((match = strongRegex.exec(line)) !== null) {
        const startIndex = match.index;
        const endIndex = strongRegex.lastIndex - 9;
        const plainText = line.substring(lastIndex, startIndex);

        if (plainText) {
          elements.push(<span>{plainText}</span>);
        }

        const boldText = line.substring(startIndex + 8, endIndex);
        elements.push(<strong>{boldText}</strong>);

        lastIndex = strongRegex.lastIndex;
      }

      const remainingText = line.substring(lastIndex);
      if (remainingText) {
        elements.push(<span>{remainingText}</span>);
      }

      if (index !== lines.length - 1) {
        elements.push(<br />);
      }
    });
    return elements;
  };

  return (
    <AntTooltip placement="right" title={parseText(title)}>
      <QuestionCircleOutlined style={{ marginLeft: '4px' }} />
    </AntTooltip>
  );
};
