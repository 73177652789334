import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useMnemonicPhraseStore } from '@/entities/mnemonic/store/mnemonic-phrase.store';
import { SecureJsonSerializer } from '@/shared/lib/secure-json/core/secure-json-serializer';
import { useSecureJsonCollectionsStore } from '@/shared/lib/stores/secure-json-collections.store';
import { useAuthStore } from '@/shared/store/auth.store';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { useSettingsStore } from '@/shared/store/settings.store';

import {
  SyncQueueJobMethod,
  useSyncQueueStore,
} from '../lib/synchronizer/sync-queue.store';

export const DebugPanel: FC = () => {
  const sjCollectionsStore = useSecureJsonCollectionsStore();
  const authStore = useAuthStore();
  const mnemonicPhraseStore = useMnemonicPhraseStore();
  const settingsStore = useSettingsStore();
  const decryptedKeyPairStore = useKeyPairStore();
  const serializer = new SecureJsonSerializer();

  return (
    <>
      <CollapsePanel title="Debug">
        <CollapsePanel title={'Decrypted key pair'}>
          <Code>{JSON.stringify(decryptedKeyPairStore, null, 2)}</Code>
        </CollapsePanel>

        <CollapsePanel title={'Secure JSON collections'}>
          <DebugButton onClick={() => console.log(sjCollectionsStore)}>
            Log zustand sjdb storage
          </DebugButton>
          <CollapsePanel title={'Collections'}>
            {Array.from(sjCollectionsStore.collections.keys()).map((key) => {
              const collection = sjCollectionsStore.collections.get(key);
              if (!collection) {
                return null;
              }
              return (
                <React.Fragment key={key}>
                  <br />
                  Collection: <strong>{key}</strong>
                  <Code>{serializer.toString(collection)}</Code>
                </React.Fragment>
              );
            })}
          </CollapsePanel>
        </CollapsePanel>

        <CollapsePanel title={'Auth'}>
          <Code>{JSON.stringify(authStore, null, 2)}</Code>
        </CollapsePanel>

        <CollapsePanel title={'Mnemonic phrase'}>
          <Code>{JSON.stringify(mnemonicPhraseStore, null, 2)}</Code>
        </CollapsePanel>

        <CollapsePanel title={'Settings'}>
          <Code>{JSON.stringify(settingsStore, null, 2)}</Code>
        </CollapsePanel>

        <SynchronizerPanel />
      </CollapsePanel>
    </>
  );
};

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const SynchronizerPanel: FC = () => {
  const decryptedKeyPairStore = useKeyPairStore();
  if (!decryptedKeyPairStore.keyPair) {
    return <DebugButton>Synchronizer: Key pair is not defined</DebugButton>;
  }

  const syncQueueStore = useSyncQueueStore();
  const store = useSecureJsonCollectionsStore();
  const prevSjStore = usePrevious(store);

  async function pullGql() {
    console.time('pull');
    syncQueueStore.addJob({
      method: SyncQueueJobMethod.PULL,
    });
    console.timeEnd('pull');
  }

  async function pushGql() {
    console.time('push');
    for (const collection of store.collections) {
      const [collectionName, secureJsonBase] = collection;
      if (
        prevSjStore?.collections.get(collectionName)?.toString() ===
        secureJsonBase.toString()
      ) {
        console.log(
          `Synchronizer: collection ${collectionName} not changed, skipping`,
        );
        continue;
      }
      syncQueueStore.addJob({
        method: SyncQueueJobMethod.PUSH,
        collections: [
          {
            collectionName,
            collection: secureJsonBase.toString(),
          },
        ],
      });
      console.log(`Synchronizer: added job for collection ${collectionName}`);
    }

    console.timeEnd('push');
  }

  async function logSyncQueue() {
    console.log(syncQueueStore.jobs);
  }

  return (
    <>
      <CollapsePanel title="Synchronizer">
        <DebugButton onClick={pullGql}>Pull gql</DebugButton>
        <DebugButton onClick={pushGql}>Push gql</DebugButton>
        <DebugButton onClick={logSyncQueue}>Log sync queue</DebugButton>
      </CollapsePanel>
    </>
  );
};

const DebugButton = styled.button`
  border: 1px solid #000;
  padding: 0.5rem;
`;

const Code = styled.pre`
  background-color: #eee;
  padding: 1rem;
`;

interface ICollapsePanelProps {
  title: string;
  children: React.ReactNode;
}

const CollapsePanel: FC<ICollapsePanelProps> = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div
      style={{
        borderBottom: '1px solid #000',
      }}
    >
      <DebugButton onClick={() => setCollapsed(!collapsed)}>
        {props.title}
      </DebugButton>
      {!collapsed && <div>{props.children}</div>}
    </div>
  );
};
