import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Tooltip } from 'chart.js';

ChartJS.register(ArcElement, Tooltip);

export const DoughnutChart = ({
  countries,
  value,
}: {
  countries: Array<string>;
  value: Array<number>;
}) => {
  console.log({ value });

  const data = {
    labels: countries,
    datasets: [
      {
        label: '',
        data: value,
        backgroundColor: [
          '#000000',
          '#000080',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        hoverOffset: 4,
      },
    ],
  };
  return <Doughnut data={data} />;
};
